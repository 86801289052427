<template>
  <div class="v-stack gap-3 h-stretch" v-if="expense">
    <div ref="overlay" class="overlay">
      <div class="card dynamic">
        <div class="content mt-3">
          <div class="v-stack h-stretch gap-5">
            <div>
              Are you sure you want to delete this expense? It will delete all
              reccuring copies as well.
            </div>
          </div>
        </div>
        <div class="footer gap-3 h-space-between mt-3">
          <button @click="dismiss()" class="submit">Cancel</button>
          <button @click="remove()" class="edit">Delete</button>
        </div>
      </div>
    </div>

    <div class="card light">
      <div class="content h-stretch">
        <div class="v-stack gap-3 h-stretch">
          <label class="text-left required">Title</label>
          <input v-model="expense.name" type="text" />
          <label class="text-left required">Amount</label>
          <input v-model="expense.amount" type="number" />
          <label class="text-left required">Date</label>
          <InputDatePicker v-model="expense.date"></InputDatePicker>
          <label class="text-left required">Category</label>
          <input v-model="expense.category" type="text" list="categorylist" />
          <datalist id="categorylist">
            <option
              v-for="category in categories"
              :key="category"
              :value="category"
            ></option>
          </datalist>
          <label class="text-left required">Reccuring</label>
          <input type="checkbox" v-model="expense.reccuring" />
          <template v-if="expense.reccuring">
            <label class="text-left"
              >Repeat till (leave blank to repeat forever)</label
            >
            <div class="pane-right gap-3">
              <InputDatePicker
                v-model="expense.reccuringEndDate"
              ></InputDatePicker>
              <button class="edit" @click="clearEndDate()">X</button>
            </div>
          </template>
          <label class="text-left">Store</label>
          <input v-model="expense.store" type="text" />
          <label class="text-left">Description</label>
          <textarea v-model="expense.description" />
          <div class="h-stack h-space-between">
            <div v-if="!this.id"></div>
            <button v-if="this.id" @click="removeDialog()" class="edit">
              Delete
            </button>
            <button v-if="this.id" @click="submit()" class="submit">
              Submit
            </button>
            <button v-if="!this.id" @click="submit()" class="submit">
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import InputDatePicker from "@/components/calendar/InputDatePicker.vue";

export default {
  props: ["id"],
  data() {
    return {
      expense: null,
      categories: [],
    };
  },
  components: {
    InputDatePicker,
  },
  methods: {
    ...mapActions([
      "addExpense",
      "getExpense",
      "updateExpense",
      "removeExpense",
      "getExpenses",
    ]),
    clearEndDate() {
      this.expense.reccuringEndDate = null;
    },
    submit() {
      if (this.expense.name.length > 0 && this.expense.amount > 0) {
        if (this.id) {
          this.expense.category = this.expense.category.trim().toLowerCase();
          this.updateExpense(this.expense)
            .then(() => {
              this.$router.go(-1);
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.expense.category = this.expense.category.trim().toLowerCase();
          this.addExpense(this.expense)
            .then(() => {
              this.$router.go(-1);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    },
    removeDialog() {
      this.$refs.overlay.classList.add("show");
    },
    dismiss() {
      this.$refs.overlay.classList.remove("show");
    },
    remove() {
      this.removeExpense(this.id)
        .then(() => {
          this.$router.go(-1);
        })
        .catch((error) => {
          console.log(error);
        });
      this.dismiss();
    },
  },
  mounted() {
    if (this.id) {
      this.getExpense(this.id)
        .then((expense) => {
          this.expense = expense;
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.expense = {
        name: "",
        amount: 0,
        description: "",
        category: "",
        date: moment().toISOString(),
        reccuring: false,
        reccuringEndDate: null,
        store: "",
      };
    }

    this.getExpenses()
      .then((expenses) => {
        this.categories = Array.from(
          new Set(expenses.map((expense) => expense.category))
        );
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>
